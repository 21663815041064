import React, { useCallback } from 'react';
import { IFlow, IOption } from '..';
import { Line } from '../styles';

interface IOptions {
  options: IOption[];
  handleSelectFlow(flow: IFlow, optionId?: number): void;
}

const Options: React.FC<IOptions> = ({ options, handleSelectFlow }) => {
  const countRows = useCallback((flow: IFlow) => {
    let rows = 0;
    if (flow.options) {
      rows = flow.options.length;

      flow.options.forEach((option) => {
        if (option.flow) {
          // Chamada recursiva para contar options nos níveis inferiores
          rows += countRows(option.flow);
        }
      });
    }

    return rows;
  }, []);
  return (
    <>
      {options.map((option, index) => (
        <div className="pt-2 ps-5">
          <div className="d-flex align-items-center">
            <Line
              className="option-selector"
              rows={options[index - 1] ? countRows(options[index - 1].flow) : 0}
            />
            <button
              type="button"
              className="flow"
              onClick={() => handleSelectFlow(option.flow, option.id)}
            >
              {option.flow.name}
            </button>
          </div>
          {option.flow.options && option.flow.options.length > 0 && (
            <Options
              options={option.flow.options}
              handleSelectFlow={handleSelectFlow}
            />
          )}
        </div>
      ))}
    </>
  );
};

export default Options;
