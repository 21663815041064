// import axios from 'axios';
import React, { createContext, useCallback, useState, useContext } from 'react';
import Swal from 'sweetalert2';

import api from '../services/api';

interface IMenu {
  id: string;
  title: string;
  link: string;
  icon: string;
  type: string;
  sub_menus: IMenu[];
}

interface IUserTypeMenu {
  menu: IMenu;
}

export interface IUser {
  id: number;
  name: string;
  email: string;
  type: {
    types_menus: IUserTypeMenu[];
  };
}

interface AuthState {
  token: string;
  user: IUser;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: IUser;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: IUser): void;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@CoiPrime:token');
    const user = localStorage.getItem('@CoiPrime:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }: SignInCredentials) => {
    try {
      const response = await api.post('users/sessions', {
        email,
        password,
      });

      const { token, user } = response.data;

      localStorage.setItem('@CoiPrime:token', token);
      localStorage.setItem('@CoiPrime:user', JSON.stringify(user));

      api.defaults.headers.authorization = `Bearer ${token}`;

      setData({ token, user });
    } catch (error) {
      Swal.fire('Opss...', 'Código de autenticação inválido', 'error');
    }
  }, []);

  const signOut = useCallback(async () => {
    localStorage.removeItem('@CoiPrime:token');
    localStorage.removeItem('@CoiPrime:user');

    await api.post('users/sessions/signout');

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user) => {
      localStorage.setItem('@CoiPrime:user', JSON.stringify(user));

      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token]
  );

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, updateUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
