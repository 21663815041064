import styled from 'styled-components';

export const Container = styled.div`
  z-index: 1;

  button {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
    align-items: center;
  }

  video {
    width: 100%;
    object-fit: cover;
    border-radius: 25px;
  }

  .btn-play {
    width: 68px;
    height: 68px;
    border-radius: 50%;
    border: 1px solid #fff;
    background: rgba(109, 109, 109, 0.45);
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 991px) {
    video {
      border-radius: 15px;
    }
  }
`;
