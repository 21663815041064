import React from 'react';

import { AuthProvider } from './Auth';
import { ChatbotProvider } from './Chatbot';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <ChatbotProvider>{children}</ChatbotProvider>
  </AuthProvider>
);

export default AppProvider;
