/* eslint-disable no-async-promise-executor */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { QRCode } from 'react-qrcode-logo';

import { Form } from '@unform/web';
import { FaCheck } from 'react-icons/fa6';
import { LuUnplug } from 'react-icons/lu';
import api from '~/services/api';

import { Container } from './styles';
import FlowModal from './FlowModal';
import Options from './Options';
import Input from '~/components/Input';
import InputCheckbox, {
  IOption as IOptionCheckbox,
} from '~/components/InputCheckbox';
import Toast from '~/utils/toast';
import { useChatbot } from '~/hooks/Chatbot';

export interface IOption {
  id: number;
  content: string;
  selector: number;
  status: 'new' | 'old';
  flow: IFlow;
}

export interface IFlow {
  id: number;
  name: string;
  message: string;
  status: 'new' | 'old';
  options: IOption[];
  user_type_id?: number;
  action: string;
}

interface IFlowResponse {
  id: number;
  name: string;
  message: string;
  options: IOption[];
  user_type_id?: number;
  action: string;
}

interface IChatbotSetting {
  id: number;
  webhook_url: string;
  days: string;
  start_hour: string;
  end_hour: string;
}

const Chatbot: React.FC = () => {
  const { connected, qrCode } = useChatbot();
  const location = useLocation();
  const [flows, setFlows] = useState<IFlow[]>([]);
  const [oldFlows, setOldFlows] = useState<IFlow[]>([]);
  const [flowSelected, setFlowSelected] = useState({} as IFlow);
  const [show, setShow] = useState(false);
  const [optionIdSelcted, setOptionIdSelcted] = useState<number | undefined>(
    undefined
  );
  const [webhook, setWebhook] = useState('');
  const [startHour, setStartHour] = useState('');
  const [endHour, setEndHour] = useState('');
  const [days, setDays] = useState<IOptionCheckbox[]>([
    { label: 'Domingo', value: 'sunday', selected: false },
    {
      label: 'Segunda',
      value: 'monday',
      selected: false,
    },
    {
      label: 'Terça',
      value: 'tuesday',
      selected: false,
    },
    {
      label: 'Quarta',
      value: 'wednesday',
      selected: false,
    },
    {
      label: 'Quinta',
      value: 'thursday',
      selected: false,
    },
    {
      label: 'Sexta',
      value: 'friday',
      selected: false,
    },
    { label: 'Sábado', value: 'saturday', selected: false },
  ]);

  useEffect(() => {
    api.get<IChatbotSetting>('chatbot-settings').then((response) => {
      if (Object.keys(response.data).length > 0) {
        const daysData = response.data.days?.split(', ') || [];

        setWebhook(response.data.webhook_url);
        setDays((oldState) => {
          return oldState.map((day) => {
            return {
              ...day,
              selected: daysData.includes(day.value as string),
            };
          });
        });
        setStartHour(response.data.start_hour);
        setEndHour(response.data.end_hour);
      }
    });
  }, []);

  const handleLoadFlows = useCallback(async () => {
    const response = await api.get<IFlowResponse[]>('flows');

    const data = response.data.map<IFlow>((flow) => {
      return {
        id: flow.id,
        name: flow.name,
        message: flow.message,
        status: 'old',
        options: flow.options.map<IOption>((option) => ({
          ...option,
          status: 'old',
        })),
        user_type_id: flow.user_type_id,
        action: flow.action,
      };
    });

    setFlows(data);
    setOldFlows(data);
  }, []);

  useEffect(() => {
    handleLoadFlows();
  }, [handleLoadFlows, location.pathname]);

  const handleClose = useCallback(() => {
    setShow(false);
    setFlowSelected({} as IFlow);
    setOptionIdSelcted(undefined);
  }, []);

  const handleSelectFlow = useCallback((flow, optionId?: number) => {
    setFlowSelected(flow);
    setOptionIdSelcted(optionId);
    setShow(true);
  }, []);

  const updateFlowRecursively = useCallback(
    (oldFlow: IFlow, newFlow: IFlow): IFlow => {
      if (oldFlow.id === newFlow.id) {
        return { ...newFlow };
      }
      return {
        ...oldFlow,
        options: oldFlow.options.map((option) => {
          return {
            ...option,
            flow: updateFlowRecursively(option.flow, newFlow),
          };
        }),
      };
    },
    []
  );

  const getFlow = useCallback(async (options: IOption[], flow: IFlow) => {
    return new Promise<IFlow | undefined>(async (resolve) => {
      for (const option of options) {
        if (option.flow.id === flow.id) {
          resolve(option.flow);
          return; // Para garantir que a execução pare aqui
        }
        if (option.flow.options.length > 0) {
          const flowData = await getFlow(option.flow.options, flow);
          if (flowData) {
            resolve(flowData);
            return; // Para garantir que a execução pare aqui
          }
        }
      }

      // Se nenhum fluxo foi encontrado, resolve com undefined
      resolve(undefined);
    });
  }, []);

  const handleSaveFlow = useCallback(
    async (flow: IFlow) => {
      const newFlow = flow;

      const formData = {
        option_id: optionIdSelcted,
        name: newFlow.name,
        message: newFlow.message,
        action: newFlow.action,
        user_type_id: newFlow.user_type_id,
      };

      const options = oldFlows.map<IOption>((oldFlow, index) => ({
        id: new Date().getTime(),
        content: oldFlow.name,
        flow: oldFlow,
        selector: index + 1,
        status: 'old',
      }));

      const oldFlowData = await getFlow(options, newFlow);

      const optionsDeleted: IOption[] = [];
      if (oldFlowData) {
        const optionsData = oldFlowData.options.filter(
          (oldOption) =>
            !flow.options.some((option) => option.id === oldOption.id)
        );

        optionsDeleted.push(...optionsData);
      }

      let flowId = newFlow.id;
      if (newFlow.status === 'new') {
        const response = await api.post('flows', formData);
        flowId = response.data.id;
      } else {
        await api.put(`flows/${flowId}`, formData);
      }

      if (newFlow.options && newFlow.options.length > 0) {
        let index = 0;
        for (const option of newFlow.options) {
          const formDataOption = {
            flow_id: flowId,
            content: option.content,
            selector: option.selector,
          };

          let optionId = option.id;
          let optionFlowId = option.flow.id;
          if (option.status === 'new') {
            const response = await api.post('options', formDataOption);
            const flowFormData = {
              option_id: response.data.id,
              name: option.content,
              action: 'options',
            };
            const responseFlow = await api.post('flows', flowFormData);
            optionId = response.data.id;
            optionFlowId = responseFlow.data.id;
          } else {
            const flowFormData = {
              option_id: optionId,
              name: option.content,
              message: option.flow.message,
            };

            await api.put(`flows/${option.flow.id}`, flowFormData);
            await api.put(`options/${optionId}`, formDataOption);
          }

          newFlow.options[index].id = optionId;
          newFlow.options[index].status = 'old';
          newFlow.options[index].flow.id = optionFlowId;
          newFlow.options[index].flow.status = 'old';

          index += 1;
        }
      }

      if (optionsDeleted.length > 0) {
        for (const optionDeleted of optionsDeleted) {
          await api.delete(`flows/${optionDeleted.flow.id}`);
          await api.delete(`options/${optionDeleted.id}`);
        }
      }

      newFlow.id = flowId;
      newFlow.status = 'old';

      const newFlows = flows.map((flowData) => {
        if (flowData) {
          if (flowData.id === newFlow.id) {
            return {
              ...newFlow,
            };
          }

          if (flowData.options && flowData.options.length > 0) {
            return {
              ...flowData,
              options: flowData.options.map((option) => {
                if (option.flow.id === newFlow.id) {
                  return {
                    ...option,
                    flow: newFlow,
                  };
                }
                return {
                  ...option,
                  flow: updateFlowRecursively(option.flow, newFlow),
                };
              }),
            };
          }
        }
        return flowData;
      });

      setFlows(newFlows);
      setOldFlows(newFlows);
      setFlowSelected({} as IFlow);
      setShow(false);
      setOptionIdSelcted(undefined);
    },
    [flows, getFlow, oldFlows, optionIdSelcted, updateFlowRecursively]
  );

  const handleSubmitOpenDays = useCallback(async () => {
    const daysSelected = days
      .filter((day) => day.selected)
      .map((day) => day.value);

    await api.put('chatbot-settings/1', { days: daysSelected.join(', ') });

    Toast.fire({
      icon: 'success',
      title: 'Dias de funcionamento salvo!',
    });
  }, [days]);

  const handleSubmitOpenHours = useCallback(async (data) => {
    const { start_hour, end_hour } = data;
    await api.put('chatbot-settings/1', { start_hour, end_hour });

    Toast.fire({
      icon: 'success',
      title: 'Horário de funcionamento salvo!',
    });
  }, []);

  return (
    <Container className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex justify-content-between align-items-center mb-5">
            <h1 className="h2">Configurações - Chatbot</h1>
          </div>
          <div className="col-12 mb-3">
            <div className="d-flex aling-items-center justify-content-between">
              <p className="mb-0">
                Status:{' '}
                <span className={connected ? 'text-success' : 'text-danger'}>
                  {connected ? 'Conectado' : 'Desconectado'}
                </span>
              </p>
              {!connected && (
                <button type="button" className="btn btn-primary">
                  <LuUnplug size={24} color="#fff" /> Conectar
                </button>
              )}
            </div>
            {!connected && (
              <>
                <Form onSubmit={(data) => console.log(data)} className="mb-3">
                  <label className="w-100">
                    <span>Webhook</span>
                    <div className="d-flex align-items-center">
                      <Input
                        type="url"
                        name="webhook"
                        value={webhook}
                        onChange={(e) => setWebhook(e.target.value)}
                      />
                      <button
                        type="submit"
                        className="bg-transparent border-0 ms-3"
                      >
                        <FaCheck size={24} color="#ad6211" />
                      </button>
                    </div>
                  </label>
                </Form>
                {qrCode && (
                  <div>
                    <label>QR Code</label>
                    <div className="d-flex justify-content-start align-items-center my-3">
                      <QRCode
                        value={qrCode}
                        // removeQrCodeBehindLogo
                        // logoImage={logo}
                        // logoPadding={5}
                        size={250}
                        ecLevel="L"
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="col-lg-7 mb-3">
            <Form onSubmit={handleSubmitOpenDays} className="mb-3">
              <label className="w-100">
                <span>Dias de funcionamento</span>
              </label>
              <div
                className="d-flex align-items-center"
                style={{
                  height: 46,
                }}
              >
                <InputCheckbox
                  type="checkbox"
                  name="days"
                  options={days}
                  onChange={(data) => setDays(data)}
                  className="flex-wrap justify-content-start input-checkbox"
                />
                <button type="submit" className="bg-transparent border-0 ms-3">
                  <FaCheck size={24} color="#ad6211" />
                </button>
              </div>
            </Form>
          </div>
          <div className="col-lg-5 mb-3">
            <Form onSubmit={handleSubmitOpenHours} className="mb-3">
              <label className="w-100">
                <span>Horário de funcionamento</span>
                <div className="d-flex align-items-center">
                  <Input
                    type="time"
                    name="start_hour"
                    value={startHour}
                    onChange={(e) => setStartHour(e.target.value)}
                  />
                  <span className="mx-2">-</span>
                  <Input
                    type="time"
                    name="end_hour"
                    value={endHour}
                    onChange={(e) => setEndHour(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="bg-transparent border-0 ms-3"
                  >
                    <FaCheck size={24} color="#ad6211" />
                  </button>
                </div>
              </label>
            </Form>
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-between align-items-center mt-3">
            <h2 className="h3">Fluxo de mensagens automáticas</h2>
          </div>
          <div className="col-12">
            {flows.map((flow) => (
              <div key={flow.id} className="box p-4">
                <div>
                  <button
                    type="button"
                    className="flow"
                    onClick={() => handleSelectFlow(flow)}
                  >
                    {flow.name}
                  </button>
                  {flow.options.length > 0 && (
                    <Options
                      options={flow.options}
                      handleSelectFlow={handleSelectFlow}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {Object.keys(flowSelected).length > 0 && (
        <FlowModal
          flow={flowSelected}
          show={show}
          onHide={handleClose}
          onSave={handleSaveFlow}
        />
      )}
    </Container>
  );
};

export default Chatbot;
