// import axios from 'axios';
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';

import api from '../services/api';
import { useAuth } from './Auth';

export interface IMessage {
  id: string;
  chat_id: string;
  me: boolean;
  avatar: string;
  name: string;
  text: string;
  type: string;
  time: number;
  file_name?: string;
}

interface ChatbotContextData {
  connected: boolean;
  qrCode: string;
  message: IMessage;
}

const socket = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}/chat`);
const whatsappConnectedAux = false;

export const ChatbotContext = createContext<ChatbotContextData>(
  {} as ChatbotContextData
);

export const ChatbotProvider: React.FC = ({ children }) => {
  // const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({} as IMessage);
  const [connected, setConnected] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [connectionEstablished, setConnectionEstablished] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    if (!connectionEstablished && user) {
      socket.onopen = () => {
        const data = {
          user_id: 1,
          chat_id: user.id,
        };

        socket.send(JSON.stringify(data));
        setConnectionEstablished(true);
      };
    }
  }, [connectionEstablished, user]);

  const handleGenerateQrCode = useCallback(async () => {
    let webHookLink = '';
    try {
      const response = await api.get('whatsapp');
      webHookLink = response.data.session.webhookLink;
      setConnected(true);
    } catch (error) {
      setConnected(false);
    }

    if (webHookLink) {
      const formData = {
        sessionName: 'coiPrime',
        webhookLink: webHookLink,
      };
      const response = await api.post('whatsapp', formData);

      setQrCode(response.data.qrCode);
    }
  }, []);

  const handleCheckConnected = useCallback(async () => {
    api.get('chats/connected').then((response) => {
      // whatsappConnectedAux = !!response.data.connected;
      setConnected(!!response.data.connected);
    });
  }, []);

  useEffect(() => {
    socket.onerror = () => {
      if (!whatsappConnectedAux) {
        setInterval(() => {
          handleGenerateQrCode();
        }, 60 * 1000);
      }
      setInterval(() => {
        handleCheckConnected();
      }, 5 * 1000);
    };
  }, [handleCheckConnected, handleGenerateQrCode]);

  useEffect(() => {
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.qrCode) {
        setQrCode(data.qrCode);
      } else if (data.connected) {
        setConnected(data.connected);
      } else {
        const messageData = {
          id: data.id,
          chat_id: data.chatId,
          me: false,
          avatar: data.avatar,
          name: data.name,
          text: data.text,
          type: data.type,
          time: data.time,
          file_name: data.file_name,
        };

        // eslint-disable-next-line no-console
        console.log(messageData);
        setMessage(messageData);
      }
    };
  }, []);

  useEffect(() => {
    handleCheckConnected();
  }, [handleCheckConnected]);

  return (
    <ChatbotContext.Provider value={{ connected, qrCode, message }}>
      {children}
    </ChatbotContext.Provider>
  );
};

export function useChatbot(): ChatbotContextData {
  const context = useContext(ChatbotContext);

  if (!context) {
    throw new Error('useChatbot must be used within an ChatbotProvider');
  }

  return context;
}
