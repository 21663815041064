import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '~/pages/SignIn';
import ForgotPassword from '~/pages/ForgotPassword';
import ChangePassword from '~/pages/ChangePassword';
import Dashboard from '~/pages/Dashboard';
import Blog from '~/pages/Blog';
import BlogNew from '~/pages/Blog/New';
import BlogUpdate from '~/pages/Blog/Update';
import Chats from '~/pages/Chats';
import UsersTypes from '~/pages/UsersTypes';
import Employees from '~/pages/Employees';
import SettingsChatbot from '~/pages/Settings/Chatbot';

const routes: React.FC = () => {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={SignIn} />
      <Route
        path={`${process.env.PUBLIC_URL}/esqueci-minha-senha`}
        exact
        component={ForgotPassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/recuperar-senha/:token`}
        exact
        component={ChangePassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/dashboard`}
        exact
        component={Dashboard}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/blog`}
        exact
        component={Blog}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/blog/novo`}
        exact
        component={BlogNew}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/blog/:slug`}
        exact
        component={BlogUpdate}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/equipe`}
        exact
        component={() => (
          <div className="container" style={{ height: '100vh' }}>
            <div className="row h-100">
              <div className="col-12 h-100 d-flex align-items-center justify-content-center">
                <p className="h1">Em desenvolvimento!</p>
              </div>
            </div>
          </div>
        )}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/depoimentos`}
        exact
        component={() => (
          <div className="container" style={{ height: '100vh' }}>
            <div className="row h-100">
              <div className="col-12 h-100 d-flex align-items-center justify-content-center">
                <p className="h1">Em desenvolvimento!</p>
              </div>
            </div>
          </div>
        )}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/conversas`}
        exact
        component={Chats}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/conversas/:reference`}
        exact
        component={Chats}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/tipos-de-acessos`}
        component={UsersTypes}
        exact
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/funcionarios`}
        component={Employees}
        exact
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/configuracoes/chatbot`}
        component={SettingsChatbot}
        exact
        isPrivate
      />
    </Switch>
  );
};

export default routes;
