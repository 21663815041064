/* eslint-disable no-underscore-dangle */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { IoSend } from 'react-icons/io5';
import * as yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Swal from 'sweetalert2';

import { BsChatDots } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';
import { IoMdMore } from 'react-icons/io';
import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';

import {
  Container,
  Content,
  Notifications,
  ChatArea,
  ChatText,
  Options,
  BallonBox,
} from './styles';
import Textarea from '~/components/Textarea';
import Toast from '~/utils/toast';
import Player from '~/components/Player';
import { IMessage, useChatbot } from '~/hooks/Chatbot';

interface IChat {
  id: string;
  user_id?: string;
  avatar: string;
  name: string;
  message: string;
  status?: string;
  newMessage?: boolean;
  messages: IMessage[];
}

interface IChatUser {
  chat: IChat;
}

interface IChatResponse {
  data: IChatUser[];
}

// interface IMessageResponse {
//   id: string;
//   user_id?: number;
//   chat_id: string;
//   avatar_id: string;
//   temp_user_id: number;
//   name: string;
//   content: string;
//   time: number;
//   approved: boolean;
//   avatar: {
//     avatar_url: string;
//   };
// }

interface IParams {
  reference?: string;
}

const Chats: React.FC = () => {
  const history = useHistory();
  const params = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const { message } = useChatbot();
  const [chats, setChats] = useState<IChat[]>([]);
  const [chatSelected, setChatSelected] = useState({} as IChat);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [loading, setLoading] = useState(false);
  const [messageSelected, setMessageSelected] = useState({} as IMessage);
  const [reply, setReply] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    if (Object.keys(message).length > 0) {
      setChats((state) => {
        const newChats = state.slice();
        const chatIndex = newChats.findIndex(
          (chat) => chat.id === message.chat_id
        );
        if (chatIndex >= 0) {
          newChats[chatIndex].message = message.text;
          newChats[chatIndex].newMessage = message.chat_id !== chatSelected.id;
          return newChats;
        }
        return [
          {
            id: message.chat_id,
            avatar: message.avatar,
            name: message.name,
            message: message.text,
            messages: [],
            newMessage: true,
          },
          ...state,
        ];
      });
      if (chatSelected.id === message.chat_id) {
        setMessages((state) => {
          return [message, ...state];
        });
      }
    }
  }, [chatSelected.id, message]);

  const handleLoadChats = useCallback(async () => {
    const response = await api.get<IChatResponse>('chats-users', {
      params: {
        type: 'owner',
      },
    });
    const data = response.data.data.map((userChat) => userChat.chat);
    setChats(data);
    setLoading(false);
  }, []);

  const handleLoadMessages = useCallback(async () => {
    if (params.reference) {
      const response = await api.get<IChat>(`chats/${params.reference}`);
      const data = response.data.messages.map((messageData) => ({
        id: messageData.id,
        chat_id: messageData.chat_id,
        me: !messageData.avatar,
        avatar: messageData.avatar,
        name: !messageData.avatar ? 'Você' : messageData.name,
        text: messageData.text,
        type: messageData.type,
        time: messageData.time,
        file_name: messageData.file_name,
      }));
      setMessages(data.reverse());
      setChatSelected({
        id: response.data.id,
        avatar: response.data.avatar,
        name: response.data.name,
        message: response.data.message,
        messages: [],
      });
      setChats((state) => {
        const newChats = state.slice();
        const chatIndex = newChats.findIndex(
          (chat) => chat.id === response.data.id
        );
        if (chatIndex >= 0) {
          newChats[chatIndex].newMessage = false;
        }

        return newChats;
      });
    }
  }, [params.reference]);

  useEffect(() => {
    setLoading(true);
    handleLoadChats().finally(() => setLoading(false));
  }, [handleLoadChats]);

  useEffect(() => {
    handleLoadMessages();
  }, [handleLoadMessages]);

  const handleInfiniteLoad = useCallback(async () => {
    // console.log('AQUI');
  }, []);

  const handleKeyPress = useCallback((e) => {
    if (
      (e.keyCode === 13 || e.which === 13 || e.key === 'Enter') &&
      !e.shiftKey
    ) {
      e.preventDefault();
      formRef.current?.submitForm();
    }
  }, []);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          message: yup.string().required('Message is required'),
        });

        await schema.validate(data, { abortEarly: false });

        const formData = {
          chat_id: chatSelected.id,
          content: data.message,
        };

        await api.post('messages', formData);

        setMessages((state) => {
          return [
            {
              id: new Date().getTime().toString(),
              chat_id: chatSelected.id,
              me: true,
              avatar: '',
              name: 'Você',
              text: data.message,
              type: data.type || 'message',
              time: new Date().getTime(),
              file_name: data.file_name,
            },
            ...state,
          ];
        });

        const newChats = chats.slice();
        const chatIndex = newChats.findIndex(
          (chat) => chat.id === chatSelected.id
        );

        if (chatIndex >= 0) {
          newChats[chatIndex].message = data.message;
        }

        setChats(newChats);
        setReply(false);
        setMessageSelected({} as IMessage);
        formRef.current?.reset();
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Looks like what you were trying to do didn’t work, please try again.',
            'error'
          );
        }
      }
    },
    [chatSelected.id, chats]
  );

  const handleCloseReply = useCallback(() => {
    setReply(false);
    setMessageSelected({} as IMessage);
  }, []);

  const handleClickOptions = useCallback(() => {
    setShowOptions((state) => !state);
  }, []);

  const handleClickFinalizeChat = useCallback(() => {
    Swal.fire({
      title: `Realmente deseja finalizar esta conversa?`,
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#be7e38',
      cancelButtonColor: '#707070',
      cancelButtonText: 'Não',
      reverseButtons: true,
      iconColor: '#be7e38',
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          await api.post(`chats/${chatSelected.id}/finalize`);
          Toast.fire({
            icon: 'success',
            title: 'Conversa finalizada!',
          });

          setChatSelected({} as IChat);
          history.push(`${process.env.PUBLIC_URL}/conversas`);
        }
      })
      .catch(() => {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      });
  }, [chatSelected.id, history]);

  return (
    <Container className="py-5">
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Content className="p-4">
              <div className="row justify-content-between">
                <div className="col-lg-3">
                  <Notifications>
                    <div className="height-notifications overflow-auto">
                      {chats.map((data) => (
                        <Link
                          key={data.id}
                          to={`${process.env.PUBLIC_URL}/conversas/${data.id}`}
                          className="d-flex justify-content-between cursor-pointer notifications-hover p-3 w-100 mb-2"
                        >
                          <div className="d-flex">
                            <img
                              src={data.avatar}
                              alt="Avatar"
                              className="avatar me-2"
                            />
                            <div>
                              <h2 className="h6 font-weight-medium mb-2">
                                {data.name}
                              </h2>
                              <p className="mb-0">
                                {data.message.length > 24
                                  ? `${data.message.slice(0, 24)}…`
                                  : data.message}
                              </p>
                            </div>
                          </div>
                          {data.newMessage && <span className="notification" />}
                        </Link>
                      ))}
                    </div>
                  </Notifications>
                </div>
                <ChatArea className="col-lg-9">
                  {params.reference && Object.keys(chatSelected).length > 0 ? (
                    <Form ref={formRef} onSubmit={handleSubmit} className="p-4">
                      <div className="border-user d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img
                            src={chatSelected.avatar}
                            alt="Avatar"
                            className="avatar-profile"
                          />

                          <h2 className="ms-3 mb-0">{chatSelected.name}</h2>
                        </div>
                        <div className="position-relative">
                          <button
                            type="button"
                            className="border-0 bg-transparent"
                            onClick={handleClickOptions}
                          >
                            <IoMdMore size={24} color="#202020" />
                          </button>
                          <Options show={showOptions}>
                            <button
                              type="button"
                              className="border-0 p-3 btn-option"
                              onClick={handleClickFinalizeChat}
                            >
                              Finalizar conversa
                            </button>
                          </Options>
                        </div>
                      </div>
                      <ChatText
                        reverse
                        scrollLoadThreshold={100}
                        onInfiniteLoad={handleInfiniteLoad}
                        className="bodyChat p-4"
                      >
                        {messages.map((messageData) => (
                          <Fragment key={messageData.id}>
                            <BallonBox
                              me={messageData.me}
                              className="w-100 d-flex mb-2"
                            >
                              <div className="d-flex align-items-end">
                                {!messageData.me && (
                                  <img
                                    src={messageData.avatar}
                                    alt="Avatar"
                                    className="avatar me-2"
                                  />
                                )}
                                <div>
                                  <span className="d-block name-chat mb-2">
                                    {messageData.name}
                                  </span>
                                  <div className="d-flex align-items-center">
                                    <div className="bg-light-gray pt-2 pb-2 px-2 mb-2 ballon">
                                      {messageData.type === 'image' && (
                                        <img
                                          src={messageData.text}
                                          alt={messageData.text}
                                          className="w-100"
                                        />
                                      )}
                                      {(messageData.type === 'video' ||
                                        messageData.type === 'gif') && (
                                        <Player
                                          id={messageData.text}
                                          src={messageData.text}
                                          thumbnail={messageData.text}
                                          className="w-100"
                                          autoPlay={messageData.type === 'gif'}
                                          muted={messageData.type === 'gif'}
                                          loop={messageData.type === 'gif'}
                                          controls={messageData.type !== 'gif'}
                                        />
                                      )}
                                      {messageData.type === 'document' && (
                                        <a
                                          href={messageData.text}
                                          title={messageData.file_name}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {messageData.file_name}
                                        </a>
                                      )}
                                      {messageData.type === 'audio' && (
                                        <audio controls>
                                          <source
                                            src={messageData.text}
                                            type="audio/mpeg"
                                          />
                                          <track
                                            default
                                            kind="captions"
                                            srcLang="en"
                                          />
                                          Seu navegador não suporta o elemento
                                          de áudio.
                                        </audio>
                                      )}
                                      {messageData.type === 'message' &&
                                        messageData.text}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </BallonBox>
                          </Fragment>
                        ))}
                      </ChatText>
                      <div className="row mb-4">
                        <div className="col-12">
                          <div className="input-box p-3">
                            <div className="p-3">
                              <div className="row align-items-center">
                                <div className="col-12">
                                  <div
                                    className={`reply ${
                                      reply ? 'active mb-2' : 'mb-0'
                                    }`}
                                  >
                                    <div className="detail" />
                                    {reply && (
                                      <div>
                                        <p className="font-weight-bold small mb-1">
                                          {messageSelected.name}
                                        </p>
                                        <p className="mb-0 small">
                                          {messageSelected.text}
                                        </p>
                                      </div>
                                    )}
                                    {reply && (
                                      <button
                                        type="button"
                                        className="btn btn-close-reply"
                                        onClick={handleCloseReply}
                                      >
                                        <AiOutlineClose
                                          size={18}
                                          color="#fff"
                                        />
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-10">
                                  <Textarea
                                    id="message"
                                    name="message"
                                    className="w-100 border-0 textarea"
                                    placeholder="Message"
                                    rows={2}
                                    onKeyPress={handleKeyPress}
                                  />
                                </div>
                                <div className="col-lg-2">
                                  <button
                                    type="submit"
                                    className="d-flex justify-content-center align-items-center w-100 btn btn-primary px-3 py-2"
                                  >
                                    Enviar{' '}
                                    <IoSend
                                      size={19}
                                      color="#fff"
                                      className="ms-2"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center no-chat">
                      <div className="icon">
                        <BsChatDots color="#9C9C9C" size={50} />
                      </div>
                      <p className="h2 text-center mb-0 ms-3">
                        Selecione uma conversa
                      </p>
                    </div>
                  )}
                </ChatArea>
              </div>
            </Content>
          </div>
        </div>
      </div>
      {loading && (
        <>
          <div className="loading-box">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default Chats;
