import React, { useState, useCallback, useRef, useEffect } from 'react';
import { TbPlayerPlayFilled } from 'react-icons/tb';
import { Container } from './styles';

interface IPlayer {
  id: string;
  src: string;
  thumbnail?: string;
  onTimeUpdate?(event: React.SyntheticEvent<HTMLVideoElement, Event>): void;
  onPlay?(event: React.SyntheticEvent<HTMLVideoElement, Event>): void;
  onEnded?(event: React.SyntheticEvent<HTMLVideoElement, Event>): void;
  onLoad?(video: any): void;
  autoPlay?: boolean;
  className?: string;
  muted?: boolean;
  loop?: boolean;
  buttonId?: string;
  controls?: boolean;
}

const Player: React.FC<IPlayer> = ({
  id,
  src,
  thumbnail,
  onTimeUpdate,
  onPlay,
  onEnded,
  onLoad,
  autoPlay,
  className,
  muted,
  buttonId,
  loop,
  controls,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [play, setPlay] = useState(false);
  const [volume, setVolume] = useState<number>(1);

  useEffect(() => {
    if (autoPlay && videoRef.current) {
      setPlay(true);
      videoRef.current.play();
    }
  }, [autoPlay]);

  const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
    }
  };

  const handleVideoEnded = (
    e: React.SyntheticEvent<HTMLVideoElement, Event>
  ) => {
    const video = videoRef.current;
    if (video) {
      video.currentTime = 0;
      if (thumbnail) {
        video.setAttribute('poster', thumbnail);
      }
      video.pause();
      video.load();
    }

    if (onEnded) {
      onEnded(e);
    }
    setPlay(false);
  };

  const handlePlay = useCallback(
    (e) => {
      setPlay(true);
      if (onPlay) {
        onPlay(e);
      }
    },
    [onPlay]
  );

  const handleClick = useCallback(() => {
    const vid = document.getElementById(id) as HTMLVideoElement;
    if (vid) {
      if (!play) {
        vid.play();
      } else {
        vid.pause();
      }
    }

    setPlay((state) => !state);
  }, [id, play]);

  return (
    <Container className={`video w-100 position-relative ${className}`}>
      <video
        controls={controls && play}
        playsInline
        poster={thumbnail}
        id={id}
        loop={loop}
        onTimeUpdate={(e) => {
          if (onTimeUpdate) {
            onTimeUpdate(e);
          }
        }}
        onPlay={handlePlay}
        onEnded={(e) => {
          if (onEnded) {
            onEnded(e);
          }
          handleVideoEnded(e);
        }}
        // onPause={() => setPlay(false)}
        ref={videoRef}
        onLoadedData={onLoad}
        muted={muted}
      >
        <source src={src} type="video/mp4" />
        <track default kind="captions" srcLang="en" />
        Sorry, your browser doesn't support embedded videos.
      </video>
      {!play && (
        <button
          id={buttonId}
          type="button"
          className="d-flex position-absolute border-0 bg-transparent w-100 h-100"
          onClick={handleClick}
        >
          <div className="btn-play">
            <TbPlayerPlayFilled size={32} color="#fff" />
          </div>
        </button>
      )}
    </Container>
  );
};

export default Player;
